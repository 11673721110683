import React from "react";
import { ArrowRight, Headphones } from "lucide-react";

const HeroSection = () => {
  return (
    <div className="relative bg-gradient-to-br from-indigo-900 via-purple-900 to-blue-900 text-white min-h-screen flex items-center overflow-hidden">
      <div className="container mx-auto px-4 py-8 pt-18 sm:py-12 md:py-16">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 mb-8 md:mb-0 md:pr-8">
            <div className="bg-white/10 text-xs sm:text-sm font-semibold py-1 px-3 rounded-full inline-block mb-4 sm:mb-6">
              Boost Your Productivity
            </div>
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 sm:mb-6 leading-tight">
              Focus-Driven Melodies
            </h1>
            <p className="text-base sm:text-lg md:text-xl mb-6 sm:mb-8 max-w-lg opacity-90">
              Elevate your work sessions with curated playlists designed to
              enhance concentration and boost productivity.
            </p>
            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
              <button className="bg-white text-indigo-900 font-bold py-3 px-6 rounded-full hover:bg-opacity-90 transition duration-300 flex items-center justify-center shadow-lg">
                Try for free
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button className="text-white font-bold py-3 px-6 rounded-full hover:bg-white/10 transition duration-300 border border-white/20">
                Explore Playlist
              </button>
            </div>
          </div>
          <div className="w-full md:w-1/2 mt-8 md:mt-0">
            <div className="relative w-full aspect-[4/3] max-w-md mx-auto md:max-w-none">
              <div className="absolute inset-0 bg-gradient-to-br from-blue-400 to-purple-400 rounded-2xl filter blur-3xl opacity-50 animate-pulse"></div>
              <div
                className="absolute inset-0 bg-gradient-to-tr from-pink-400 to-yellow-400 rounded-2xl filter blur-3xl opacity-50 animate-pulse"
                style={{ animationDelay: "1s" }}
              ></div>
              <img
                src="https://images.unsplash.com/photo-1513258496099-48168024aec0?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Person with headphones representing focus and audio knowledge"
                className="relative z-10 rounded-2xl shadow-2xl w-full h-full object-cover"
              />
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white/20 backdrop-blur-md rounded-full p-4 z-20 shadow-lg">
                <Headphones className="w-8 h-8 sm:w-12 sm:h-12 text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Blur Effect */}
      <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-b from-transparent to-gray-900/50 backdrop-blur-sm"></div>

      {/* Divider */}
      <div className="absolute bottom-0 left-0 w-full overflow-hidden line-height-0 transform rotate-180">
        <svg
          className="relative block w-full h-15 sm:h-22 md:h-30"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="fill-gray-900"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default HeroSection;
