import React, { useState, useEffect, useRef } from 'react';
import { Play, Pause, Clock } from 'lucide-react';

const PlaylistCard = ({ id, title, category, duration, image, audioSrc, isPlaying, onPlayToggle }) => {
  const audioRef = useRef(new Audio(audioSrc));

  useEffect(() => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    return () => {
      audioRef.current.pause();
    };
  }, []);

  return (
    <div className={`relative bg-gray-800 rounded-xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg hover:-translate-y-1 ${isPlaying ? 'playing' : ''}`}>
      <div className="relative z-10">
        <div className="relative">
          <img src={image} alt={title} className="w-full h-48 object-cover" />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent flex items-end p-4">
            <button 
              onClick={() => onPlayToggle(id)}
              className="bg-white bg-opacity-50 hover:bg-opacity-80 text-deep-blue rounded-full p-2 transition-all duration-300 transform hover:scale-110 z-10"
            >
              {isPlaying ? <Pause size={24} /> : <Play size={24} />}
            </button>
          </div>
        </div>
        <div className="p-4">
          <h3 className="text-lg font-bold text-gray-100 mb-1 truncate">{title}</h3>
          <p className="text-gray-500 text-sm mb-2">{category}</p>
          <div className="flex items-center text-gray-400 text-sm">
            <Clock size={16} className="mr-1" />
            {duration}
          </div>
        </div>
      </div>
      <style jsx>{`
        @keyframes galacticWave {
          0%, 100% { 
            box-shadow: 1 1 5px #4F46E5, 10 10 30px #4F46E5, 0 15 45px #4F46E5;
            opacity: 0.2;
          }
          50% { 
            box-shadow: 0 0 30px #818CF8, 0 0 60px #818CF8, 0 0 90px #818CF8;
            opacity: 0.4;
          }
        }
        .playing::before {
          content: '';
          position: absolute;
          top: -6px; right: -6px; bottom: -6px; left: -6px;
          background: linear-gradient(45deg, #4F46E5, #818CF8, #4F46E5);
          border-radius: inherit;
          z-index: 0;
          animation: galacticWave 5s ease-in-out infinite;
        }
        .playing {
          transform: scale(1.02);
          transition: transform 0.2s ease-in-out;
        }
      `}</style>
    </div>
  );
};

const PopularPlaylists = () => {
  const [playlists, setPlaylists] = useState([
    { id: 1, title: "Midnight in Manhattan", category: "Jazz", duration: "2h 30m", image: "https://images.unsplash.com/photo-1525365411884-1b2fc20ea368?q=80&w=2671&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", audioSrc: "/music/jazz.mp3", isPlaying: false },
    { id: 2, title: "Konnichiwa", category: "Japanese Lofi", duration: "1h 45m", image: "https://images.unsplash.com/photo-1676782988343-e358e8d8869e?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", audioSrc: "/music/japan.mp3", isPlaying: false },
    { id: 3, title: "Coder's Rhythm", category: "Orchestra, Dramatic", duration: "3h 15m", image: "https://images.unsplash.com/photo-1477039181047-efb4357d01bd?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", audioSrc: "/music/dramatic.mp3", isPlaying: false },
    { id: 4, title: "Creative Flow", category: "Classical", duration: "2h", image: "https://images.unsplash.com/photo-1601388152430-4ad0f14c0788?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", audioSrc: "/music/classical.mp3", isPlaying: false }
  ]);

  const handlePlayToggle = (id) => {
    setPlaylists(playlists.map(playlist => 
      playlist.id === id 
        ? { ...playlist, isPlaying: !playlist.isPlaying } 
        : { ...playlist, isPlaying: false }
    ));
  };

  return (
    <section className="bg-gray-900 py-8 sm:py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl sm:text-4xl font-bold text-white mb-8 sm:mb-12 text-center">Popular Playlists</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
          {playlists.map((playlist) => (
            <PlaylistCard 
              key={playlist.id} 
              {...playlist} 
              onPlayToggle={handlePlayToggle} 
            />
          ))}
          <div className="flex items-center justify-center col-span-1 sm:col-span-2 lg:col-span-1">
            <button className="bg-white hover:bg-opacity-90 text-deep-blue font-bold py-3 px-6 rounded-full transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1 max-w-xs w-full lg:w-auto">
              Explore All Playlists
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopularPlaylists;