import React from 'react';
import HowItWorks from './HowItWorks';
import HeroSection from './HeroSection';
import KeyFeatures from './FeaturesSection';
import PopularPlaylists from './PopularSections';
import PricingPlans from './PricingPlans';
import Testimonials from './Testimonials';
import Footer from './Footer';
import Header from './Header';


function App() {
  return (
    <div className="App">
      <Header></Header>
      <HeroSection></HeroSection>
      <KeyFeatures></KeyFeatures>
      <HowItWorks />

      <PopularPlaylists></PopularPlaylists>
      <Testimonials></Testimonials>

      <PricingPlans></PricingPlans>
      <Footer></Footer>


    </div>
  );
}

export default App;