import React from 'react';
import { UserPlus, Headphones, TrendingUp } from 'lucide-react';

const steps = [
  {
    name: 'Sign Up',
    description: 'Create your Productive.fm account in seconds.',
    icon: UserPlus,
  },
  {
    name: 'Choose Playlist',
    description: 'Select from our curated focus-enhancing playlists.',
    icon: Headphones,
  },
  {
    name: 'Boost Productivity',
    description: 'Enjoy distraction-free music and track your progress.',
    icon: TrendingUp,
  },
];

const HowItWorks = () => {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-base font-semibold leading-7 text-green-400">Streamlined Process</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            How Productive.fm Works
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Get started with Productive.fm in just three simple steps and transform your work sessions.
          </p>
        </div>
        <div className="mt-16 grid gap-8 sm:grid-cols-1 lg:grid-cols-3">
          {steps.map((step, stepIdx) => (
            <div key={step.name} className="relative bg-gray-800 rounded-lg p-8 shadow-lg">
              <div className="absolute bottom-[-20px] left-[-20px] text-6xl font-bold text-blue-200 opacity-40">
                {stepIdx + 1}
              </div>
              <div className="flex items-center mb-4">
                <div className="flex-shrink-0 w-12 h-12 rounded-full bg-green-400 flex items-center justify-center">
                  <step.icon className="h-6 w-6 text-gray-900" aria-hidden="true" />
                </div>
              </div>
              <div className="flex-grow">
                <h3 className="text-lg font-semibold text-white mb-2">{step.name}</h3>
                <p className="text-base text-gray-300">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
