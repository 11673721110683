import React, { useState, useEffect } from 'react';
import { Menu, X, Headphones } from 'lucide-react';

const NavItem = ({ href, children }) => (
  <a href={href} className="relative text-gray-200 hover:text-white transition-colors group">
    {children}
    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-[#10B981] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
  </a>
);

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
      isScrolled ? 'bg-[#000000]/0 backdrop-blur-lg' : 'bg-transparent'
    }`}>
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-20">
        <img src='logoProductive.svg'></img>
          
          <nav className="hidden md:flex space-x-8">
            <NavItem href="/features">Features</NavItem>
            <NavItem href="/playlists">Playlists</NavItem>
            <NavItem href="/pricing">Pricing</NavItem>
          </nav>
          
          <div className="hidden md:flex space-x-4">
            <a href="http://app.productive.fm/" className="px-6 py-2 rounded-full border border-white  text-white hover:bg-[#10B981] hover:text-white transition-colors duration-300">
              Log In
            </a>
            <a href="http://app.productive.fm/" className="px-6 py-2 rounded-full bg-white text-black hover:from-[#0E9F6E] hover:to-[#2563EB] transition-colors duration-300">
              Sign Up
            </a>
          </div>
          
          <button className="md:hidden text-white" onClick={toggleMenu}>
            {isMenuOpen ? <X size={28} /> : <Menu size={28} />}
          </button>
        </div>
        
        {isMenuOpen && (
          <div className="md:hidden pb-6 pt-2">
            <nav className="flex flex-col space-y-4">
              <NavItem href="/features">Features</NavItem>
              <NavItem href="/playlists">Playlists</NavItem>
              <NavItem href="/pricing">Pricing</NavItem>
            </nav>
            <div className="mt-6 flex flex-col space-y-3">
              <a href="http://app.productive.fm/" className="px-6 py-2 rounded-full border border-[#10B981] text-[#10B981] hover:bg-[#10B981] hover:text-white transition-colors duration-300 text-center">
                Log In
              </a>
              <a href="http://app.productive.fm/" className="px-6 py-2 rounded-full bg-gradient-to-r from-[#10B981] to-[#3B82F6] text-white hover:from-[#0E9F6E] hover:to-[#2563EB] transition-colors duration-300 text-center">
                Sign Up
              </a>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;