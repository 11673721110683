import React from 'react';
import { Twitter } from 'lucide-react';

const testimonials = [
  {
    name: 'Darrell Steward',
    username: '@andrebiachi',
    content: "This tool has dramatically increased my productivity over the past few months. The interface is user-friendly and integrates seamlessly with my workflow. Highly recommend for anyone looking to get more organized!",
    date: '10:10 AM · Nov 19, 2023'
  },
  {
    name: 'Kristin Watson',
    username: '@afonsoinocente',
    content: "Undoubtedly the best project management software available. It helps me stay on top of deadlines and facilitates smooth collaboration with my team.",
    date: '10:10 AM · Nov 19, 2023'
  },
  {
    name: 'Bessie Cooper',
    username: '@amandadasilva',
    content: "The customer support is exceptional! Every query is met with a swift and helpful response. The regular updates consistently improve an already great product.",
    date: '10:10 AM · Nov 19, 2023'
  },
  {
    name: 'Cody Fisher',
    username: '@brunopadilha',
    content: "As a freelancer, I struggled to find a comprehensive tool until now. This app has revolutionized my time management and project delivery.",
    date: '10:10 AM · Nov 19, 2023'
  },
  {
    name: 'Ronald Richards',
    username: '@izabelarodrigues',
    content: "From its sleek design to robust features, this tool is engineered for peak productivity. It has become the central hub for all my work activities.",
    date: '10:10 AM · Nov 19, 2023'
  },
  // ... (other testimonials)
];

const TestimonialCard = ({ name, username, content, date }) => (
  <div className="bg-gray-800 rounded-lg p-6 shadow-lg w-80 h-64 flex flex-col justify-between flex-shrink-0">
    <div>
      <div className="flex items-center mb-4">
        <img 
          src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2680&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
          alt={name}
          className="w-12 h-12 rounded-full mr-4 object-cover"
        />
        <div>
          <h3 className="text-white font-semibold">{name}</h3>
          <p className="text-gray-400 text-sm">{username}</p>
        </div>
      </div>
      <p className="text-gray-300 text-sm mb-4 line-clamp-3">{content}</p>
    </div>
    <div>
      <p className="text-gray-500 text-xs mb-2">{date}</p>
      <Twitter className="text-blue-400 w-5 h-5" />
    </div>
  </div>
);

const TestimonialsSection = () => {
  return (
    <div className="bg-gray-900 text-white py-16 w-full overflow-hidden">
      <style>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
        .scrolling-testimonials-fast {
          animation: scroll 60s linear infinite;
        }
        .scrolling-testimonials-slow {
          animation: scroll 90s linear infinite;
        }
        .scrolling-testimonials-fast:hover,
        .scrolling-testimonials-slow:hover {
          animation-play-state: paused;
        }
      `}</style>
      <div className="w-full">
        <h2 className="text-4xl font-bold mb-6 text-center">Testimonials from our friends.</h2>
        <p className="text-gray-400 mb-12 max-w-3xl mx-auto text-center px-4">
          Whether you are tackling complex projects or managing daily tasks, our solution adapts to
          your unique challenges to help you stay ahead. Join the community of professionals who
          have transformed their work life.
        </p>
        <div className="relative overflow-hidden mb-8">
          <div className="scrolling-testimonials-fast flex gap-6">
            {[...testimonials, ...testimonials].map((testimonial, index) => (
              <TestimonialCard key={`fast-${index}`} {...testimonial} />
            ))}
          </div>
        </div>
        <div className="relative overflow-hidden">
          <div className="scrolling-testimonials-slow flex gap-6">
            {[...testimonials.reverse(), ...testimonials].map((testimonial, index) => (
              <TestimonialCard key={`slow-${index}`} {...testimonial} />
            ))}
          </div>
        </div>
        <div className="mt-12 flex justify-center space-x-6">
          <button className="bg-gray-800 text-white px-6 py-3 rounded-full hover:bg-gray-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
            Learn more →
          </button>
          <button className="bg-white text-gray-900 px-6 py-3 rounded-full hover:bg-gray-100 transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white">
            Sign Up Now →
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;