import React from 'react';
import { Facebook, Twitter, Instagram, Linkedin, Music } from 'lucide-react';

const FooterSection = ({ title, links }) => (
  <div className="mb-8 md:mb-0">
    <h3 className="text-lg font-semibold text-white mb-4">{title}</h3>
    <ul className="space-y-2">
      {links.map((link, index) => (
        <li key={index}>
          <a href={link.href} className="text-gray-300 hover:text-white transition-colors">
            {link.label}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

const SocialIcon = ({ Icon, href }) => (
  <a href={href} className="text-gray-300 hover:text-white transition-colors" target="_blank" rel="noopener noreferrer">
    <Icon size={24} />
  </a>
);

const Footer = () => {
  const quickLinks = [
    { label: 'Home', href: '/' },
    { label: 'Features', href: '/features' },
    { label: 'Pricing', href: '/pricing' },
    { label: 'Blog', href: '/blog' },
  ];

  const supportLinks = [
    { label: 'FAQ', href: '/faq' },
    { label: 'Contact Us', href: '/contact' },
    { label: 'Help Center', href: '/help' },
  ];

  const legalLinks = [
    { label: 'Terms of Service', href: '/terms' },
    { label: 'Privacy Policy', href: '/privacy' },
    { label: 'Cookie Policy', href: '/cookies' },
  ];

  const socialLinks = [
    { Icon: Facebook, href: 'https://facebook.com/productive.fm' },
    { Icon: Twitter, href: 'https://twitter.com/productive_fm' },
    { Icon: Instagram, href: 'https://instagram.com/productive.fm' },
    { Icon: Linkedin, href: 'https://linkedin.com/company/productive-fm' },
    { Icon: Music, href: '/playlists' },
  ];

  return (
    <footer className="bg-gray-900 text-white py-16 w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          <div className="col-span-1 md:col-span-2">
            <h2 className="text-3xl font-bold mb-6">Productive.fm</h2>
            <p className="text-gray-300 mb-8 text-lg">
              Enhance your focus and boost productivity with curated music playlists.
            </p>
            <div className="flex space-x-6">
              {socialLinks.map((link, index) => (
                <SocialIcon key={index} {...link} />
              ))}
            </div>
          </div>
          <FooterSection title="Quick Links" links={quickLinks} />
          <div>
            <FooterSection title="Support" links={supportLinks} />
            <FooterSection title="Legal" links={legalLinks} />
          </div>
        </div>
        <div className="mt-16 pt-8 border-t border-gray-800 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Productive.fm. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;