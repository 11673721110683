import React from 'react';
import { CheckCircle } from 'lucide-react';

const PricingCard = ({ title, price, period, features, isPopular, ctaText }) => (
  <div className={`bg-opacity-10 bg-white backdrop-filter backdrop-blur-lg rounded-xl overflow-hidden transition-all duration-300 hover:scale-105 flex flex-col h-full ${isPopular ? 'border border-purple-500' : ''}`}>
    <div className={`p-6 ${isPopular ? 'max-w-full md:max-w-7xl bg-gradient-to-r from-purple-900 to-indigo-900' : 'bg-gradient-to-r from-gray-700 to-gray-800'}`}>
      <h3 className="text-2xl font-bold mb-2 text-white">{title}</h3>
      <div className="text-4xl font-bold mb-2 text-white">{price}</div>
      <div className="text-sm text-gray-300">{period}</div>
    </div>
    <div className="p-6 flex-grow">
      <ul className="mb-6 space-y-3">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center text-gray-300">
            <CheckCircle className="w-5 h-5 mr-2 text-purple-500" />
            {feature}
          </li>
        ))}
      </ul>
    </div>
    <div className="p-6">
      <button className={`w-full py-3 px-4 rounded-full font-bold transition-colors ${
        isPopular 
          ? 'bg-purple-600 text-white hover:bg-purple-700' 
          : 'bg-indigo-600 text-white hover:bg-indigo-700'
      }`}>
        {ctaText}
      </button>
    </div>
  </div>
);


const PricingPlans = () => {
  const plans = [
    {
      title: "Free Trial",
      price: "$0",
      period: "14 days",
      features: [
        "10 hours/week streaming",
        "Basic playlists",
        "Standard audio quality"
      ],
      ctaText: "Start Free Trial"
    },
    {
      title: "Monthly Plan",
      price: "$1.99",
      period: "per month",
      features: [
        "Unlimited streaming",
        "Ad-free experience",
        "Full playlist access",
        "High-quality audio"
      ],
      ctaText: "Choose Monthly"
    },
    {
      title: "Annual Plan",
      price: "$19.99",
      period: "per year",
      features: [
        "All Monthly Plan features",
        "2 months free",
        "Offline playback",
        "Exclusive pro-curated playlists"
      ],
      isPopular: true,
      ctaText: "Choose Annual"
    }
  ];

  return (
    <section className="bg-gray-900 py-20 relative overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCI+PGNpcmNsZSBjeD0iMiIgY3k9IjIiIHI9IjEiIGZpbGw9IiNGRkYiIGZpbGwtb3BhY2l0eT0iMC4xIi8+PC9zdmc+')] bg-repeat"></div>
      </div>
      <div className="container mx-auto px-4 relative z-10">
        <h2 className="text-4xl font-bold text-center text-white mb-4">Pricing Plans</h2>
        <p className="text-center text-gray-300 mb-12 max-w-2xl mx-auto">Choose the perfect plan to enhance your productivity and focus. Upgrade or downgrade anytime.</p>
        <div className="grid max-width-80 grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <PricingCard key={index} {...plan} />
          ))}
        </div>
        <div className="mt-12 text-center">
          <p className="text-gray-400">All plans include our 30-day money-back guarantee</p>
        </div>
      </div>
    </section>
  );
};

export default PricingPlans;