import React from 'react';
import { Headphones, Layout, BarChart, Sparkles } from 'lucide-react';

const FeatureCard = ({ icon, title, description }) => (
  <div className="rounded-xl p-6 transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
    <div className="text-green-400 mb-4">{icon}</div>
    <h3 className="text-xl font-bold text-gray-300 mb-2">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </div>
);

const KeyFeatures = () => {
  const features = [
    {
      icon: <Headphones size={36} />,
      title: "Curated Focus Playlists",
      description: "Discover expertly crafted playlists designed to enhance concentration and boost productivity."
    },
    {
      icon: <Layout size={36} />,
      title: "Distraction-Free Interface",
      description: "Immerse yourself in a clean, minimal design that keeps you in the zone and focused on your tasks."
    },
    {
      icon: <BarChart size={36} />,
      title: "Productivity Tracking",
      description: "Monitor your focus sessions and track progress over time with intuitive analytics."
    },
    {
      icon: <Sparkles size={36} />,
      title: "Smart Recommendations",
      description: "Get personalized playlist suggestions based on your listening history and work patterns."
    }
  ];

  return (
    <section className="bg-gray-900 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-white mb-12">Key Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyFeatures;